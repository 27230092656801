// 统计word
<template>
  <div class="box">
    <v-container>
      <v-card
        class="pa-5"
      >
        存量项目总体情况：项目总数<b class="font-weight-black">{{ ProjectTotal.total }}</b>个，包含：推介项目<b class="font-weight-black">{{ ProjectTotal.tj }}</b>个，在谈项目<b class="font-weight-black">{{ ProjectTotal.zt }}</b>个，签约项目<b class="font-weight-black">{{ ProjectTotal.qy }}</b>个。
      </v-card>
      <div class="py-3" />
      <base-material-card
        inline
        icon="mdi-clipboard-text"
        title="2020年各市存量项目情况(1)"
        class="px-5 py-3"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-menu
              ref="startDate"
              v-model="startDateMenu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="开始时间"
                  prepend-icon="fa fa-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="startDateMenu = false"
                >
                  取消
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.startDate.save(date)"
                >
                  确认
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-menu
              ref="endDate"
              v-model="endDateMenu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="结束时间"
                  prepend-icon="fa fa-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  取消
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.endDate.save(date)"
                >
                  确认
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-btn
              color="success"
              @click="getCityTotal()"
            >
              <v-icon
                left
              >
                fa fa-search
              </v-icon>
              筛选
            </v-btn>
          </v-col>
        </v-row>
        <table
          class="base_table"
        >
          <thead>
            <tr>
              <th
                rowspan="2"
                class="text-center"
              >
                地市
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                项目数
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                推介项目数
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                在谈项目数
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                签约项目数
              </th>
            </tr>
            <tr>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(cityTotal, index) in cityTotals"
              :key="index"
            >
              <td>{{ cityTotal.name }}</td>
              <td>{{ cityTotal.total }}</td>
              <td>{{ (cityTotal.total/ProjectTotal.total*100).toFixed(2) }}%</td>
              <td>{{ cityTotal.tjTotal }}</td>
              <td>{{ (cityTotal.tjTotal/ProjectTotal.tj*100).toFixed(2) }}%</td>
              <td>{{ cityTotal.ztTotal }}</td>
              <td>{{ (cityTotal.ztTotal/ProjectTotal.zt*100).toFixed(2) }}%</td>
              <td>{{ cityTotal.qyTotal }}</td>
              <td>{{ (cityTotal.qyTotal/ProjectTotal.qy*100).toFixed(2) }}%</td>
            </tr>
          </tbody>
        </table>
      </base-material-card>
      <div class="py-3" />
      <base-material-card
        inline
        icon="mdi-clipboard-text"
        title="2020年第*季度各市存量项目情况(2)"
        class="px-5 py-3"
      >
        <table
          class="base_table"
        >
          <thead>
            <tr>
              <th
                rowspan="2"
                class="text-center"
              >
                地市
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                新一代信息技术
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                高端装备
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                新能源新材料
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                现代海洋
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                医养健康
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                高端化工
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                现代高效农业
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                文化创意
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                精品旅游
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                现代金融
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                其他
              </th>
            </tr>
            <tr>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(tops, index) in TopTotals"
              :key="index"
            >
              <td>{{ tops.name }}</td>
              <td>{{ tops.xydxxjsTotal }}</td>
              <td>{{ tops.xydxxjsTotalProjectTotal }}%</td>
              <td>{{ tops.gdzbTotal }}</td>
              <td>{{ tops.gdzbTotalProjectTotal }}%</td>
              <td>{{ tops.xnyxclTotal }}</td>
              <td>{{ tops.xnyxclTotalProjectTotal }}%</td>
              <td>{{ tops.xdhyTotal }}</td>
              <td>{{ tops.xdhyTotalProjectTotal }}%</td>
              <td>{{ tops.yyjkTotal }}</td>
              <td>{{ tops.yyjkTotalProjectTotal }}%</td>
              <td>{{ tops.gdhgTotal }}</td>
              <td>{{ tops.gdhgTotalProjectTotal }}%</td>
              <td>{{ tops.xdgxnyTotal }}</td>
              <td>{{ tops.xdgxnyTotalProjectTotal }}%</td>
              <td>{{ tops.whcyTotal }}</td>
              <td>{{ tops.whcyTotalProjectTotal }}%</td>
              <td>{{ tops.jplyTotal }}</td>
              <td>{{ tops.jplyTotalProjectTotal }}%</td>
              <td>{{ tops.xdjrTotal }}</td>
              <td>{{ tops.xdjrTotalProjectTotal }}%</td>
              <td>{{ tops.qtTotal }}</td>
              <td>{{ tops.qtTotalProjectTotal }}%</td>
            </tr>
          </tbody>
        </table>
      </base-material-card>
      <div class="py-3" />
      <base-material-card
        inline
        icon="mdi-clipboard-text"
        title="2020年第*季度各市存量项目情况(3)"
        class="px-5 py-3"
      >
        <table
          class="base_table"
        >
          <thead>
            <tr>
              <th
                rowspan="2"
                class="text-center"
              >
                地市
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                通过
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                正在审核
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                驳回（彻底驳回）
              </th>
            </tr>
            <tr>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(stateObj, index) in stateTotal"
              :key="index"
            >
              <td>{{ stateObj.name }}</td>
              <td>{{ stateObj.tg }}</td>
              <td>{{ (stateObj.tg/stateObj.total*100).toFixed(2) }}%</td>
              <td>{{ stateObj.sh }}</td>
              <td>{{ (stateObj.sh/stateObj.total*100).toFixed(2) }}%</td>
              <td>{{ stateObj.bh }}</td>
              <td>{{ (stateObj.bh/stateObj.total*100).toFixed(2) }}%</td>
            </tr>
          </tbody>
        </table>
      </base-material-card>
      <div class="py-3" />
      <base-material-card
        inline
        icon="mdi-clipboard-text"
        title="2020年第*季度各市存量项目情况(4)"
        class="px-5 py-3"
      >
        <table
          class="base_table"
        >
          <thead>
            <tr>
              <th
                rowspan="2"
                class="text-center"
              >
                地市
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                第二届儒商大会
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                日韩储备项目
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                补短板项目
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                各市填报推介项目
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                汽车零部件项目
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                钢铁、配套、公寓、酒店
              </th>
            </tr>
            <tr>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                占比%
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(labelObj, index) in labelTotal"
              :key="index"
            >
              <td>{{ labelObj.name }}</td>
              <td>{{ labelObj.dejrsdhTotal }}</td>
              <td>{{ labelObj.dejrsdhTotalProportion }}%</td>
              <td>{{ labelObj.rhcbxmTotal }}</td>
              <td>{{ labelObj.rhcbxmTotalProportion }}%</td>
              <td>{{ labelObj.bdbxmTotal }}</td>
              <td>{{ labelObj.bdbxmTotalProportion }}%</td>
              <td>{{ labelObj.gstbtjxmTotal }}</td>
              <td>{{ labelObj.gstbtjxmTotalProportion }}%</td>
              <td>{{ labelObj.qclbjxmTotal }}</td>
              <td>{{ labelObj.qclbjxmTotalProportion }}%</td>
              <td>{{ labelObj.gtptgyjdTotal }}</td>
              <td>{{ labelObj.gtptgyjdTotalProportion }}%</td>
            </tr>
          </tbody>
        </table>
      </base-material-card>
      <div class="py-3" />
      <!-- <base-material-card
        inline
        icon="mdi-clipboard-text"
        title="2020年第*季度各市新增流转项目产业情况"
        class="px-5 py-3"
      >
        <table
          class="base_table"
        >
          <thead>
            <tr>
              <th
                rowspan="2"
                class="text-center"
              >
                地市
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                新一代信息技术
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                高端装备
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                新能源新材料
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                现代海洋
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                医养健康
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                高端化工
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                现代高效农业
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                文化创意
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                精品旅游
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                现代金融
              </th>
              <th
                colspan="2"
                class="text-center"
              >
                其他
              </th>
            </tr>
            <tr>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                金额
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                金额
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                金额
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                金额
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                金额
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                金额
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                金额
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                金额
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                金额
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                金额
              </th>
              <th class="text-center">
                个数
              </th>
              <th class="text-center">
                金额
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="index in 16"
              :key="index"
            >
              <td>济南市</td>
              <td>100</td>
              <td>12%</td>
              <td>100</td>
              <td>12%</td>
              <td>100</td>
              <td>12%</td>
              <td>100</td>
              <td>12%</td>
              <td>100</td>
              <td>12%</td>
              <td>100</td>
              <td>12%</td>
              <td>100</td>
              <td>12%</td>
              <td>100</td>
              <td>12%</td>
              <td>100</td>
              <td>12%</td>
              <td>100</td>
              <td>12%</td>
              <td>100</td>
              <td>12%</td>
            </tr>
          </tbody>
        </table>
      </base-material-card> -->
    </v-container>
  </div>
</template>
<script>
  export default {
    data: () => ({
      startDate: '',
      endDate: '',
      radios: '',
      forms: [
        'daa9091c-3837-444c-b4d2-a473f98bd9de',
        'bc13456e-cfc0-4e9c-88c1-4992e6b72c2f',
        '02669dbf-e82e-48e4-874f-ed17bcc1a6ec',
      ],
      formIds: {
        tj: {
          name: '推介',
          id: 'daa9091c-3837-444c-b4d2-a473f98bd9de',
        },
        zt: {
          name: '在谈',
          id: 'bc13456e-cfc0-4e9c-88c1-4992e6b72c2f',
        },
        qy: {
          name: '签约',
          id: '02669dbf-e82e-48e4-874f-ed17bcc1a6ec',
        },
      },
      ProjectTotal: {
        total: '',
        tj: '',
        zt: '',
        qy: '',
      },
      citys: [
        '济南市',
        '青岛市',
        '淄博市',
        '枣庄市',
        '东营市',
        '烟台市',
        '潍坊市',
        '济宁市',
        '泰安市',
        '威海市',
        '日照市',
        '临沂市',
        '德州市',
        '聊城市',
        '滨州市',
        '菏泽市',
      ],
      tops: [
        '新一代信息技术', '高端装备', '新能源新材料', '现代海洋', '医养健康', '高端化工', '现代高效农业', '文化创意', '精品旅游', '现代金融', '其他',
      ],
      cityTotals: [],
      cityTotals1: [],
      TopTotals: [],
      stateTotal: [],
      labelTotal: [],
    }),
    created () {
      this.getProjectTotal()
      // this.getStateTotal()
    },
    methods: {
      // 系统项目总数与各阶段项目数统计
      async getProjectTotal () {
        const _this = this
        await this.$axios.get('/forms/count')
          .then(res => {
            const resData = res.data.data
            let total = 0
            resData.map(function (val) {
              for (const key in _this.formIds) {
                if (val.id === _this.formIds[key].id) {
                  _this.ProjectTotal[key] = Number(val.total)
                  total += Number(val.total)
                }
              }
            })
            this.ProjectTotal.total = total
          })
          .catch(err => {
            console.log(err)
          })
        this.getCityTotal()
      },
      // 城市项目数和各阶段项目数
      async getCityTotal () {
        this.cityTotals = []
        const cityParams = {}
        if (this.startDate && this.endDate) {
          cityParams._dateFrom = this.startDate
          cityParams._dateTo = this.endDate
        }
        for (let i = 0; i < this.citys.length; i++) {
          const cityTotalObj = {}
          cityParams.city = this.citys[i]
          const cityTotalData = await this.$axios.get('/forms/' + this.forms.toString() + '/fields/city/data', {
            params: cityParams,
          })
          const cityTjTotalData = await this.$axios.get('/forms/daa9091c-3837-444c-b4d2-a473f98bd9de/fields/city/data', {
            params: cityParams,
          })
          const cityZtTotalData = await this.$axios.get('/forms/bc13456e-cfc0-4e9c-88c1-4992e6b72c2f/fields/city/data', {
            params: cityParams,
          })
          const cityQyTotalData = await this.$axios.get('/forms/02669dbf-e82e-48e4-874f-ed17bcc1a6ec/fields/city/data', {
            params: cityParams,
          })
          cityTotalObj.name = this.citys[i]
          cityTotalObj.total = cityTotalData.data.data[0] ? cityTotalData.data.data[0].total : 0
          cityTotalObj.tjTotal = cityTjTotalData.data.data[0] ? cityTjTotalData.data.data[0].total : 0
          cityTotalObj.ztTotal = cityZtTotalData.data.data[0] ? cityZtTotalData.data.data[0].total : 0
          cityTotalObj.qyTotal = cityQyTotalData.data.data[0] ? cityQyTotalData.data.data[0].total : 0
          this.cityTotals.push(cityTotalObj)
        }
        this.getTopTotal()
        // this.getLableTotal()
        console.log(this.cityTotals)
      },
      // 城市项目数和各阶段项目数
      getTopTotal () {
        const _this = this
        this.citys.map(function (val, index) {
          _this.TopTotals.push({})
          _this.TopTotals[index].name = val
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              industry: '新一代信息技术',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.TopTotals[index], 'xydxxjsTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.TopTotals[index], 'xydxxjsTotalProjectTotal', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              industry: '高端装备',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.TopTotals[index], 'gdzbTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.TopTotals[index], 'gdzbTotalProjectTotal', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              industry: '新能源新材料',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.TopTotals[index], 'xnyxclTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.TopTotals[index], 'xnyxclTotalProjectTotal', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              industry: '现代海洋',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.TopTotals[index], 'xdhyTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.TopTotals[index], 'xdhyTotalProjectTotal', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              industry: '医养健康',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.TopTotals[index], 'yyjkTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.TopTotals[index], 'yyjkTotalProjectTotal', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              industry: '高端化工',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.TopTotals[index], 'gdhgTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.TopTotals[index], 'gdhgTotalProjectTotal', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              industry: '现代高效农业',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.TopTotals[index], 'xdgxnyTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.TopTotals[index], 'xdgxnyTotalProjectTotal', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              industry: '文化创意',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.TopTotals[index], 'whcyTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.TopTotals[index], 'whcyTotalProjectTotal', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              industry: '精品旅游',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.TopTotals[index], 'jplyTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.TopTotals[index], 'jplyTotalProjectTotal', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              industry: '现代金融',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.TopTotals[index], 'xdjrTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.TopTotals[index], 'xdjrTotalProjectTotal', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              industry: '其他',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.TopTotals[index], 'qtTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.TopTotals[index], 'qtTotalProjectTotal', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
        })
        // console.log(this.TopTotals)
      },
      // 获取项目状态统计
      getStateTotal () {
        const _this = this
        const formArr = []
        for (const key in _this.formIds) {
          formArr.push(_this.formIds[key].id)
        }
        // const statusesArr = [-10, 5, 10]
        this.citys.map(function (val, index) {
          const cityObgState = {}
          cityObgState.name = val
          cityObgState.tg = 0
          cityObgState.sh = 0
          cityObgState.bh = 0
          _this.cityTotals.map(function (city) {
            if (city.name === val) {
              cityObgState.total = city.total
            }
          })
          _this.$axios.get('/forms/' + _this.forms.toString() + '/statuses2', {
            params: {
              _city: val,
            },
          })
            .then(res => {
              const resData = res.data.data
              resData.map(function (rd) {
                if (rd.status === 10) {
                  cityObgState.tg = rd.total
                } else if (rd.status === 5) {
                  cityObgState.sh = rd.total
                } else if (rd.status === -10) {
                  cityObgState.bh = rd.total
                }
              })
              _this.stateTotal.push(cityObgState)
            })
            .catch(err => {
              console.log(err)
            })
        })
      },
      // 获取各标签统计
      getLableTotal () {
        const _this = this
        this.citys.map(function (val, index) {
          _this.labelTotal.push({})
          _this.labelTotal[index].name = val
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              tags: '第二届儒商大会',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.labelTotal[index], 'dejrsdhTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.labelTotal[index], 'dejrsdhTotalProportion', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              tags: '日韩储备项目',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.labelTotal[index], 'rhcbxmTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.labelTotal[index], 'rhcbxmTotalProportion', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              tags: '补短板项目',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.labelTotal[index], 'bdbxmTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.labelTotal[index], 'bdbxmTotalProportion', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              tags: '各市填报推介项目',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.labelTotal[index], 'gstbtjxmTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.labelTotal[index], 'gstbtjxmTotalProportion', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              tags: '汽车零部件项目',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.labelTotal[index], 'qclbjxmTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.labelTotal[index], 'qclbjxmTotalProportion', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
          _this.$axios.get('/reports/query', {
            params: {
              city: val,
              tags: '钢铁、配套、公寓、酒店',
            },
          })
            .then(res => {
              const resData = res.data.data
              _this.$set(_this.labelTotal[index], 'gtptgyjdTotal', resData.totalElements)
              _this.cityTotals.map(function (cityObj) {
                if (cityObj.name === val) {
                  _this.$set(_this.labelTotal[index], 'gtptgyjdTotalProportion', (resData.totalElements / cityObj.total * 100).toFixed(2))
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
        })
      },
    },
  }
</script>
<style scoped>
  .echarts {
    width: 100%;
    height: 300px;
  }
  .navfixed{
    position: fixed;
    z-index: 6;
  }
  .base_table{
    width: 100%;
    line-height: 30px;
    text-align: center;
    border-collapse: collapse;
  }
  .base_table,.base_table tr th, .base_table tr td {
    border:1px solid #ccc;
  }
</style>
